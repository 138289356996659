@import 'styles/_theme.scss';

$lineRatio: calc($ts-16 / $ts-18);

.profileButton {
  height: 30px;
  width: 30px;
  margin-left: 6px;
  &:hover {
    background: $darkPrimary;
  }
}

.buttonActive {
  background: $darkPrimary;
}

.downArrowIcon {
  min-width: 30px;
  min-height: 30px;
  color: #FFFFFF;
}

.userInfoContainer {
  background-color: $white;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  min-width: 300px;
  position: absolute;
  top: $ss-32;
  right: 0;
  box-shadow: 0px 10.5px 36px 0px rgba(0, 0, 0, 0.19), 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
  padding-bottom: $ss-6;
  z-index: 2;
}

.invisible {
  display: none;
}

.nav {
  color: $darkestGrey;
  display: flex;
  flex-direction: column;
}

.header {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ($lineRatio * $ss-24) ($lineRatio * $ss-32) ($lineRatio * $ss-14) ($lineRatio * $ss-32);
  padding-inline-start: 0;
}

.name {
  font-size: $ts-18;
  font-weight: bold;
  color: $darkestGrey;
  margin: 0;
}

.email {
  font-size: $ts-14;
  color: $neutralGrey;
  word-wrap: break-word;
  margin: 0;
}

.divider {
  border-bottom: 1px solid $lighterGrey;
  margin-top: $ss-6;
  margin-bottom: $ss-6;
}

.menu {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ($lineRatio * $ss-6) 0 ($lineRatio * $ss-6) 0;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  & li {
    list-style: none;
  }
}

.menuItem {
  padding: $ss-8 ($lineRatio * $ss-32) $ss-8 ($lineRatio * $ss-32);
  height: $ss-32;
  line-height: $ss-32;
  cursor: pointer;

  &:hover {
    background-color: $lighterGrey;
  }

  &:focus {
    outline: 2px dotted;
    background-color: $lighterGrey;
  }
}

.icon {
  height: 1.5rem;
  fill: black;
  vertical-align: middle;
  margin-right: $ss-8;
}

.myHealthwiseItem {
  background-color: $lighterGrey;
  font-weight: 500;
  margin: 0 ($lineRatio * $ss-32) 0 ($lineRatio * $ss-32);
  padding: $ss-12 $ss-32 $ss-12 $ss-32;
  border-radius: $ss-10;
  font-size: $ts-16;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: 2px dotted;
  }
}

.menuLiHide {
  visibility: hidden;
}

.link {
  color: $darkestGrey;
  font-size: $ts-16;
  font-weight: 400;
  vertical-align: middle;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}
