@import 'styles/_theme.scss';

.rightAlignedButtonGroup {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 0 1.5rem 1.5rem;
}

.bodyText {
  color: $darkestGrey;
  text-transform: none;
  max-width: 60ch;
  white-space: pre-wrap;
  line-height: 2;
}

.fullName {
  font-weight: 500;
}

.errorText {
  color: red;
  text-align: end;
  font-size: 13px;
}
.ariaLabel{
    display:none;
}
.cancelButtonMain{
  background-color: $white;
  color: $darkGrey;
  font-weight: normal;
}