@import 'styles/_theme.scss';

.link {
  &:hover {
    text-decoration: none;
    background-color: $white;
  }
}

// All buttons should have these common styles
// Include with any button. By doing so, custom styling should be minimal. (mainly colors)
.button {
  padding: $ss-10 $ss-18;
  border-radius: $ss-8;
  height: $ss-42;
  font-size: $ts-16;
  line-height: $ss-20;
  text-transform: none;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
}

.buttonCancel {
  color: $darkGrey;
  background-color: $white;
  border: 1px solid $lightGrey;
  width: 86px;
  &:hover {
    background-color: $white;
  }
}

.greenButton {
  background-color: $primary-color;
  color: $white;
  &:hover {
    background-color: $primary-color;
  }
  &:disabled {
    background-color: $primaryDisabled;
    color: $white;
    opacity: 1;
  }
}

.outlineButtonGreen {
  border: 1px solid $primary-color;
  color: $primary-color;
  background-color: $white;
  &:hover {
    background-color: $white;
  }
}

.outlineButtonRed {
  border: 1px solid $red;
  color: $red;
  background-color: $white;
  &:hover {
    background-color: $white;
  }
}

.buttonRed {
  background-color: $red;
  color: $white;
  &:disabled {
    opacity: 0.6;
    color: $white;
  }
  &:hover {
    background-color: $red;
  }
}

.greenLink {
  color: $primary-color;
  font-size: $ts-16;
}

.outlineButtonWhite {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  color: $white;
  &:hover {
    text-decoration: none;
    background-color: $primary-color;
  }
}

.generalCancelButton {
  border: 1px solid $darkGrey;
  color: $darkGrey;
  background-color: $white;

  &:hover {
    background-color: $white;
  }
}

.confirmationModalButton {
  color: $white;
  margin-left: $ss-8;
}

.backgroundGreen {
  background-color: $primary;
  border: 1px solid $primary;

  &:hover {
    text-decoration: none;
    background-color: $primary;
  }
}

.backgroundRed {
  background-color: $red;
  border: 1px solid $red;

  &:hover {
    text-decoration: none;
    background-color: $red;
  }
}
